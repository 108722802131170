

import { defineComponent } from 'vue'
import NotFoundComponent from '@/views/components/NotFoundComponent.vue'

export default defineComponent({
  components: {
    NotFoundComponent
  },

  setup() {
    return { }
  }
})
