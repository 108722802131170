import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_not_found_component = _resolveComponent("not-found-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_not_found_component, {
      showsRetryButton: false,
      "onNotfound:retry": _ctx.reloadItem
    }, null, 8, ["onNotfound:retry"])
  ]))
}